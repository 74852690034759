import {
  InstagramLogo,
  FacebookLogo,
  Envelope,
  TelegramLogo,
  WhatsappLogo,
} from '@phosphor-icons/react';
import React from 'react';
import { sendWhatsapp } from '../utils/whatsapp';

const SocialLink = () => {
  const navIcons = [
    {
      link: 'https://www.instagram.com/intanet.official',
      icon: <InstagramLogo size={20} />,
    },
    {
      link: 'https://www.facebook.com/intanet.official',
      icon: <FacebookLogo size={20} />,
    },
    {
      link: sendWhatsapp('Halo INTANET'),
      icon: <WhatsappLogo size={20} />,
    },
    {
      link: 'mailto:office@intanet.id',
      icon: <Envelope size={20} />,
      email: 'office@intanet.id',
    },
  ];

  return (
    <ul className="lg:flex justify-end hidden gap-2 p-3">
      {navIcons.map((data, index) => (
        <a
          key={index}
          href={data.link}
          target="_blank"
          className="flex gap-2 items-center text-gray-600 hover:text-primary transition-colors"
        >
          {data.icon}
          <span>{data.email}</span>
        </a>
      ))}
    </ul>
  );
};

export default SocialLink;
