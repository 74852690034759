import { Link } from 'gatsby';
import React from 'react';
import {
  InstagramLogo,
  FacebookLogo,
  Envelope,
  TelegramLogo,
  WhatsappLogo,
  List,
  X,
} from '@phosphor-icons/react';
import clsx from 'clsx';
import { sendWhatsapp } from '../utils/whatsapp';
import SocialLink from './SocialLink';

const navlinks = [
  {
    name: 'Beranda',
    link: '/',
  },
  {
    name: 'Paket Internet',
    link: '#packages',
  },
  {
    name: 'Tentang Kami',
    link: '#about',
  },
];

const navIcons = [
  {
    link: 'https://www.instagram.com/intanet.id/',
    icon: <InstagramLogo size={20} />,
  },
  {
    link: 'https://www.facebook.com/intanet.id/',
    icon: <FacebookLogo size={20} />,
  },
  {
    link: sendWhatsapp('Halo INTANET'),
    icon: <WhatsappLogo size={20} />,
  },
  {
    link: 'mailto:office@intanet.id',
    icon: <Envelope size={20} />,
    email: 'office@intanet.id',
  },
];

const NavbarComponent = () => {
  const [active, setActive] = React.useState<string>('Beranda');
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <>
      <div className="container">
        <SocialLink />
      </div>
      <nav className="w-full font-medium sticky top-0 z-10 bg-white/40 backdrop-blur-md py-3">
        <div className="container">
          <div className="w-full flex items-center justify-center lg:justify-between py-3 relative">
            <div className="flex flex-col items-end gap-2 lg:hidden absolute right-5 top-3">
              <button onClick={() => setOpen(!open)}>
                {open ? <X size={24} /> : <List size={24} />}
              </button>
              {open && (
                <div className="bg-white py-5 px-3 shadow-md rounded-md">
                  <ul className="flex flex-col gap-5 items-center justify-center">
                    {navlinks.map((data, index) => (
                      <Link
                        key={index}
                        to={data.link}
                        onClick={() => setActive(data.name)}
                        className={clsx(
                          'hover:bg-secondary px-5 py-1 text-center rounded-md transition-colors duration-300 hover:text-gray-800',
                          active === data.name
                            ? 'bg-secondary font-medium'
                            : 'text-gray-500'
                        )}
                      >
                        {data.name}
                      </Link>
                    ))}
                    <div className="flex flex-col gap-2 w-full">
                      <a
                        target="_blank"
                        className="w-full text-center px-4 py-1 border border-primary hover:bg-primary transition-colors duration-200 hover:text-black text-primary rounded hover:opacity-85 font-semibold"
                        href="https:///ebilling.intanet.id"
                      >
                        LOGIN
                      </a>
                      <a
                        target="_blank"
                        className="w-full text-center px-4 py-1 bg-primary hover:bg-secondary transition-colors duration-200 hover:text-black text-white rounded hover:opacity-85 font-semibold"
                        href={sendWhatsapp(
                          'Halo, saya mau pasang INTANET di rumah saya'
                        )}
                      >
                        DAFTAR
                      </a>
                    </div>
                  </ul>
                </div>
              )}
            </div>
            <Link to="/">
              <img
                src={'/images/intanet-text.png'}
                alt="logo-intanet"
                className="w-40"
              />
            </Link>
            <div className="ml-10 lg:flex gap-10 hidden">
              <ul className="flex gap-5 items-center">
                {navlinks.map((data, index) => (
                  <Link
                    key={index}
                    to={data.link}
                    onClick={() => setActive(data.name)}
                    className={clsx(
                      'hover:bg-secondary px-5 py-1 text-center rounded-md transition-colors duration-300 hover:text-gray-800',
                      active === data.name
                        ? 'bg-secondary font-medium'
                        : 'text-gray-500'
                    )}
                  >
                    {data.name}
                  </Link>
                ))}
              </ul>
              <div className="flex flex-row gap-2">
                <a
                  target="_blank"
                  className="px-4 py-1 border border-primary hover:bg-primary transition-colors duration-200 hover:text-white text-primary rounded hover:opacity-85 font-semibold"
                  href="https://ebilling.intanet.id/"
                >
                  LOGIN
                </a>
                <a
                  target="_blank"
                  className="px-4 py-1 bg-primary hover:bg-secondary transition-colors duration-200 hover:text-black text-white rounded hover:opacity-85 font-semibold"
                  href={sendWhatsapp(
                    'Halo, saya mau pasang INTANET di rumah saya'
                  )}
                >
                  DAFTAR
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarComponent;
